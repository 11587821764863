import React from 'react';
import CommonHero from '../components/layout/CommonHero';
import Layout from '../components/layout/Layout';
import Seo from '../components/layout/Seo';
import ContactPageSection from '../components/Sections/ContactPageSection';

export default function ContactPage() {
    return (
        <Layout contactPage>
            <Seo
                title="Contact us"
                description="Contact us today to book your initial consultation."
            />
            <CommonHero title="contact us" />
            <ContactPageSection />
        </Layout>
    );
}
