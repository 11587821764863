import styled from '@emotion/styled';
import { Link } from 'gatsby';
import React from 'react';
import { PrimaryBtn } from '../Buttons/Primary';
import ContactDetails from '../ContactDetails';
import ContactForm from '../ContactForm';
import { padding } from '../layout/GlobalStyles';

const Section = styled.section`
    padding: 32px 0 64px;

    @media (min-width: 1280px) {
        padding: 128px 0;
    }
`;

const TextBox = styled.div`
    ${padding};
    padding: 0 16px;
    max-width: 1270px;
    margin-bottom: 64px;

    > h1 {
        margin: 0;
    }

    > p {
        color: ${({ theme }) => theme.darkTeal};
        margin: 16px 0;

        > span {
            font-size: 1rem;
            font-weight: 700;
        }
    }

    @media (min-width: 1440px) {
        max-width: 1440px;
        margin: 0 calc((100vw - 1440px) / 2.4) 58px auto;
        padding-left: 12px;

        > p {
            max-width: 1270px;
        }
    }
`;

const Flex = styled.div`
    @media (min-width: 1280px) {
        display: flex;
        background-color: ${({ theme }) => theme.kindaWhite};
    }

    @media (min-width: 1280px) {
        max-width: 1280px;
        margin: 0 auto;
        border-radius: 64px;
    }

    @media (min-width: 1600px) {
        max-width: 1440px;
        border-radius: 64px;
        margin: 0 calc((100vw - 1440px) / 2.4) 0 auto;
    }
`;

const BoxWrapper = styled.div`
    margin: 56px auto 0;
    padding: 128px 16px 0;
    max-width: 1576px;
    border-top: 1px solid;
    border-image-slice: 1;
    border-image-source: ${({ theme }) => theme.gradient};
`;

const Box = styled.div`
    padding: 32px 64px;
    background: ${({ theme }) => theme.white};
    border-radius: 32px;
    max-width: 765px;
    margin: 0 auto;
    text-align: center;

    > h3 {
        margin: 0 0 32px;
    }

    > p {
        margin: 0 0 32px;
        color: ${({ theme }) => theme.darkTeal};
    }
`;

export default function ContactPageSection() {
    return (
        <Section>
            <TextBox>
                <h1>Contact Us</h1>
                <p>Got quick questions or a simple follow up? We would be glad to help. </p>
                <p>
                    <span>NOTE:</span> Please use this form for general information purposes only.
                    DO NOT send personal health information through this form. Specific patient care
                    must be addressed during your appointment.
                </p>
            </TextBox>
            <Flex>
                {/* I didn't use flex order instead used two forms for accessibility reasons */}
                <ContactForm />
                <ContactDetails contactPage />
                <ContactForm desktop />
            </Flex>
            <BoxWrapper>
                <Box>
                    <h2>Want an Appointment Instead?</h2>
                    <p>If you know what you want, book an appointment.</p>
                    <PrimaryBtn as={Link} to="/new-patients/#contact-us">
                        book an appointment
                    </PrimaryBtn>
                </Box>
            </BoxWrapper>
        </Section>
    );
}
