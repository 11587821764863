import styled from '@emotion/styled';
import React, { useState } from 'react';
import { Form, Input, Label, Submit, Thanks } from './FormComponents';

const FormContainer = styled(Form)`
    display: ${({ desktop }) => (desktop ? 'none' : 'block')};

    @media (min-width: 768px) {
        padding: 48px 64px;
    }

    @media (min-width: 1280px) {
        display: ${({ desktop }) => (desktop ? 'block' : 'none')};
        margin: 64px auto 0;
    }

    @media (min-width: 1280px) {
        padding: 32px 64px;
    }
`;

export default function ContactForm({ desktop }) {
    const [submit, setSubmit] = useState(false);

    const handleSubmit = async e => {
        e.preventDefault();
        setSubmit(true);

        const data = Object.fromEntries(new FormData(e.target));

        await fetch(`https://api.${window.location.host}/contact`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        });

        e.target.reset();
    };
    return (
        <FormContainer desktop={desktop} onSubmit={handleSubmit}>
            <h3>get in touch</h3>
            <Label htmlFor="name">Name *</Label>
            <Input type="text" id="name" name="name" placeholder="John Doe" required />
            <Label htmlFor="phone">Phone *</Label>
            <Input type="tel" id="phone" name="phone" placeholder="(222) 222 2222" required />
            <Label htmlFor="email">Email address *</Label>
            <Input type="email" id="email" name="email" placeholder="example@gmail.com" required />
            <Label htmlForm="message">Message for the Staff/ Doctor *</Label>
            <Input
                as="textarea"
                id="message"
                name="message"
                rows="8"
                placeholder="Write your message here"
                required
            ></Input>
            <Submit as="input" type="submit" value="Contact Us" />
            <Thanks submit={submit}>
                <h1>Thank you</h1>
                <h3>Someone will be right with you shortly!</h3>
            </Thanks>
        </FormContainer>
    );
}
